import React, { useCallback, useState, useEffect } from 'react';

import { DynamicPackageSearchSummaryCondensed } from './DynamicPackageSearchSummaryCondensed';
import { NewDynamicPackageSearchForm } from './NewDynamicPackageSearchForm';
import { useSearchConfigurationContext } from './SearchConfigurationContext';
import { ClassNameProps } from '@ComponentProps';
import { getScrollBehavior } from '@Core/scroll-helpers/getScrollBehavior';
import { trackEventClick } from '@Core/tracking/hooks/useInteractionTracking';
import { SearchButton } from '@DesignSystemComponents/Search/SearchButton/SearchButton';

export const ExpandingSearchForm: React.FC<ClassNameProps> = ({ className }) => {
  const [showEdit, setShowEdit] = useState(false);
  const { setHideTabList } = useSearchConfigurationContext();

  const scrollToSearchCTA = useCallback(() => {
    const searchFormBottomMarkerDOM = document.getElementById('search-form-bottom-marker');

    if (searchFormBottomMarkerDOM) {
      searchFormBottomMarkerDOM.scrollIntoView({
        behavior: getScrollBehavior(),
        block: 'end',
      });
    }
  }, []);

  const onEdit = useCallback(() => {
    setShowEdit(true);
    trackEventClick('search-ui-landing-form-expanded');
    if (setHideTabList) {
      setHideTabList(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showEdit) {
      scrollToSearchCTA();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showEdit]);

  return (
    <div className={className}>
      <div sx={{ display: [showEdit ? 'block' : 'none', 'block'] }}>
        <NewDynamicPackageSearchForm />
        <div
          id="search-form-bottom-marker"
          sx={{ display: [null, 'none'] }}
        />
      </div>

      {!showEdit && (
        <div sx={{ display: ['block', 'none'] }}>
          <DynamicPackageSearchSummaryCondensed
            onEdit={onEdit}
            sx={{
              marginBottom: 'xs',
            }}
          />
          <SearchButton
            sx={{
              display: ['block', 'none'],
            }}
          />
        </div>
      )}
    </div>
  );
};
