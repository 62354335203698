import React, { useState } from 'react';

import { SearchConfiguration } from '@AuroraTypes';
import { ClassNameProps } from '@ComponentProps';
import { NewSearchFormExpanding } from '@Components/SearchForm/NewSearchFormExpanding';
import { SearchConfigurationContext } from '@Components/SearchForm/SearchConfigurationContext';
import { LandingSearchAvailabilityStoreUpdater } from '@Pages/landing/store-updaters/LandingSearchAvailabilityStoreUpdater';

export interface LandingSearchFormProps {
  searchConfiguration: SearchConfiguration;
}

export const LandingSearchForm: React.FC<LandingSearchFormProps & ClassNameProps> = ({
  className,
  searchConfiguration,
}) => {
  const [shouldHideTabs, setShouldHideTabs] = useState(true);

  return (
    <LandingSearchAvailabilityStoreUpdater>
      <section className={className}>
        <SearchConfigurationContext.Provider
          value={{
            showSearchButton: true,
            ...searchConfiguration,
            hideTabList: shouldHideTabs,
            setHideTabList: setShouldHideTabs,
            isFlexibilityEnabled: true,
          }}
        >
          <NewSearchFormExpanding
            sx={{
              marginTop: ['xs', 0],
            }}
          />
        </SearchConfigurationContext.Provider>
      </section>
    </LandingSearchAvailabilityStoreUpdater>
  );
};

export default LandingSearchForm;
