import {
  Tabs,
  TabHeader,
  PageContainer,
  pageContainerStyles,
  HydrateWhenInViewport,
} from '@loveholidays/design-system';
import { useTranslation } from '@loveholidays/phrasebook';
import React from 'react';

import { ExpandingSearchForm } from './ExpandingSearchForm';
import { HotelOnlySearchForm } from './HotelOnlySearchForm';
import { useSearchConfigurationContext } from './SearchConfigurationContext';
import { useAppContext } from '@Contexts/contexts';

export const NewSearchFormExpanding: React.FC = () => {
  const { t } = useTranslation();
  const { hideTabList } = useSearchConfigurationContext();
  const { site } = useAppContext();

  return (
    <Tabs
      indentedTabList={false}
      prefix="search-form"
      initialTabId="dynamic-package"
      tabListStyles={{
        ...pageContainerStyles,
        display: 'flex',
        ...(hideTabList && { display: ['none', 'flex'] }),
        ...(!hideTabList && { marginTop: ['l', 0] }),
        gap: 'xl',
        borderBottomWidth: 'outlinedStrokeWeight',
        borderStyle: 'solid',
        borderColor: 'strokeLightsubtle',
      }}
      tabPanelStyles={{
        paddingTop: 'l',
        paddingBottom: 'xl',
        backgroundColor: 'podEmphasisedBackground',
      }}
    >
      <Tabs.Tab
        id="dynamic-package"
        header={
          <TabHeader
            label={t('flightAndHotel')}
            labelVariant={['small', 'large']}
            sx={{
              color: 'textDefault',
              paddingTop: '2xs',
            }}
          />
        }
      >
        <PageContainer>
          <ExpandingSearchForm />
        </PageContainer>
      </Tabs.Tab>

      {site.supportHotelOnly && (
        <Tabs.Tab
          id="hotel-only"
          header={
            <TabHeader
              label={t('hotels')}
              labelVariant={['small', 'large']}
              sx={{
                color: 'textDefault',
                paddingTop: '2xs',
              }}
            />
          }
        >
          <HydrateWhenInViewport>
            <PageContainer>
              <HotelOnlySearchForm />
            </PageContainer>
          </HydrateWhenInViewport>
        </Tabs.Tab>
      )}
    </Tabs>
  );
};

// eslint-disable-next-line import/no-default-export
export default NewSearchFormExpanding;
